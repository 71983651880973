import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { SongConsumer } from '../components/context';
import Layout from '../components/layout';
import AudioControl from '../components/audio-control';
import '../assets/styles/pages/editions-list.scss';

const EditionsPage = ({ data }) => {
  const [editionsArr, setEditionsArr] = useState([]);

  useEffect(() => {
    const editions = [];
    data.allDatoCmsEdition.edges.forEach(({ node: edition }) => {
      if (typeof editions[edition.category.category] === 'undefined') {
        editions[edition.category.category] = [];
      }
      editions[edition.category.category].push(edition);

      return editions;
    });

    setEditionsArr(editions);
  }, []);

  return (
    <SongConsumer>
      {(context) => (
        <Layout showTitleRow="All editions">
          <div className="container">
            {
              Object.keys(editionsArr).map((idx) => (
                <div className="edition-wrapper row" key={idx}>
                  <h2 className="edition-category-title">{idx}</h2>
                  {
                    editionsArr[idx].map((edition) => (
                      <div className="edition-tile" key={edition.id}>
                        <Link to={`/editions/${edition.slug}`}>
                          {edition.trackImage && edition.trackImage.filename.split('.').pop() !== 'svg' ? (
                            <Img fluid={{ ...edition.trackImage.fluid, aspectRatio: 2 / 2 }} />
                          ) : (
                            <img src={edition.trackImage.url} alt={edition.title} />
                          )}
                          {edition.trackId}
                          <strong>{edition.name}</strong>
                          <strong><em>{edition.title}</em></strong>
                        </Link>
                        <AudioControl edition={edition} context={context} />
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </Layout>
      )}
    </SongConsumer>
  );
};

export const query = graphql`
  query EditionsQuery {
    allDatoCmsEdition(sort: { fields: [trackId, category___order], order: DESC }) {
      edges {
        node {
          id
          trackId
          slug
          title
          category {
            category
            order
          }
          name
          soundFile
          trackImage {
            fluid(maxWidth: 500) {
              ...GatsbyDatoCmsSizes
            }
            url
            filename
          }
        }
      }
    }
  }
`;

EditionsPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default EditionsPage;
